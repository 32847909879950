import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL_SHIPPING_ADDRESS = '/shipping-addresses'
const URL_SHIPPING_PRESETS = '/shipping-presets'
const URL_STORE = '/connections'
const URL_PACKAGES = '/packages'
const URL_SHIPSTATION = `/shipstation`
const URL_USERS = `/users`
const URL_COMPANIES = `/companies`
const URL_ONBOARD = `/onboard`
const PACKING_SLIP = `/settings`
const URL_NOTIFICATION = '/notifications'

// addresses
const getAddress = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING_ADDRESS}`, config)
}

const saveAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const editAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const deleteAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_ADDRESS}/delete`, payload, config)
}

// packages
const getPackages = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_PACKAGES}`, config)
}

const getPackageDetail = (id?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_PACKAGES}/${id}`, config)
}

const createPackages = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_PACKAGES}`, payload, config)
}

const editPackage = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_PACKAGES}`, payload, config)
}

const deletePackage = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_PACKAGES}/delete`, payload, config)
}

// presets
const getShippingPresets = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING_PRESETS}`, config)
}

const getDefaultPreset = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING_PRESETS}/default`, config)
}

const createShippingPreset = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_PRESETS}`, payload, config)
}

const updateShippingPreset = (
  id: string,
  payload: any,
  config?: AxiosRequestConfig | undefined
) => {
  return RequestBase.put(`${URL_SHIPPING_PRESETS}/${id}`, payload, config)
}

const setDefaultPreset = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_PRESETS}/default`, payload, config)
}

const applyShippingPreset = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_SHIPPING_PRESETS}/apply`, payload, config)
}

const deleteShippingPreset = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_PRESETS}/delete`, payload, config)
}

// store
const getConfig = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_STORE}/configurations`, config)
}

const frontToken = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPSTATION}/front-token`, payload, config)
}

const connections = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_STORE}`, config)
}

const connect = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_STORE}`, payload, config)
}

const disconnect = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL_STORE}`, config)
}

const removeStore = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL_STORE}/remove`, config)
}

const getShopifyIntegrateLink = (shopUrl, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/shopify/install-link?shop=' + shopUrl, config)
}

const getEbayConsentLink = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/ebay/consent-link', config)
}

const webhookUrl = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_STORE}/webhook-url`, config)
}

// profile
const accountEdit = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_USERS}/profile`, payload, config)
}

const changePassword = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_USERS}/profile/change-password`, payload, config)
}

const getCompany = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL_COMPANIES, config)
}

const getOnboard = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL_ONBOARD, config)
}

const updateCardOnboard = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_USERS}/profile/card-onboard`, payload, config)
}

// general
const updateGeneral = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_USERS}/profile/general`, payload, config)
}

const getNotification = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL_NOTIFICATION, config)
}

const createNotification = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(URL_NOTIFICATION, payload, config)
}

const updateNotification = (id, payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_NOTIFICATION}/${id}`, payload, config)
}

// settings
const settings = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`/settings`, config)
}

const updatePrint = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${PACKING_SLIP}/print`, payload, config)
}

const updatePackingSlip = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${PACKING_SLIP}/packing-slip`, payload, config)
}

const SettingsService = {
  // addresses
  getAddress,
  saveAddress,
  editAddress,
  deleteAddress,

  // packages
  getPackages,
  getPackageDetail,
  createPackages,
  editPackage,
  deletePackage,

  // presets
  getShippingPresets,
  getDefaultPreset,
  createShippingPreset,
  updateShippingPreset,
  setDefaultPreset,
  applyShippingPreset,
  deleteShippingPreset,

  // store
  getConfig,
  frontToken,
  connections,
  connect,
  disconnect,
  removeStore,
  getShopifyIntegrateLink,
  getEbayConsentLink,
  webhookUrl,

  // profile
  accountEdit,
  changePassword,
  getCompany,
  getOnboard,
  updateCardOnboard,

  // general
  updateGeneral,
  getNotification,
  createNotification,
  updateNotification,

  // settings
  settings,
  updatePrint,
  updatePackingSlip,
}

export default SettingsService
