/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_gori/assets/ts/_utils'
import {DisableSidebar} from '../../_gori/layout/core'
import {MasterLayoutForNoAuth} from '../../_gori/layout/MasterLayoutForNoAuth'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import {MaintenancePage} from '../modules/errors'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {ClientSignature, SalesSignature} from '../modules/agreements'
import GlobalWrapper from '../pages/global/GlobalWrapper'
import {LandingPage} from '../pages/landing/LandingPage'
import {PrivacyPolicyPage} from '../pages/landing/PrivacyPolicyPage'
import {ServiceTermsPage} from '../pages/landing/ServiceTermsPage'
import OnboardWrapper from '../pages/onboard/OnboardWrapper'
import {PrivateRoutes} from './PrivateRoutes'
import {RetoolLogin} from '../pages/login/RetoolLogin'
import {AmazonIntegration} from '../pages/integrations/AmazonIntegration'
import {ShipStationIntegration} from '../pages/integrations/ShipStationIntegration'
import {ShopifyIntegration} from '../pages/integrations/ShopifyIntegration'
import {WalmartIntegration} from '../pages/integrations/WalmartIntegration'
import {EBayIntegration} from '../pages/integrations/EBayIntegration'
import {TikTokIntegration} from '../pages/integrations/TikTokIntegration'
import {isFeatureEnabled} from '../../_gori/helpers'
import {FEATURES} from '../../_gori/constants/_features'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const {currentUser} = useAuth()
  const HelpCenterPageWrapper = lazy(() => import('../pages/help-center/HelpCenterWrapper'))

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <>
            {isFeatureEnabled(FEATURES.LANDING_PAGE) && (
              <Route path='landing/*' element={<LandingPage />} />
            )}
            {isFeatureEnabled(FEATURES.MARKETPLACE) && (
              <>
                <Route path='integrations/amazon' element={<AmazonIntegration />} />
                <Route path='integrations/shipstation' element={<ShipStationIntegration />} />
                <Route path='integrations/shopify' element={<ShopifyIntegration />} />
                <Route path='integrations/walmart' element={<WalmartIntegration />} />
                <Route path='integrations/ebay' element={<EBayIntegration />} />
                <Route path='integrations/tiktok' element={<TikTokIntegration />} />
              </>
            )}
            {isFeatureEnabled(FEATURES.SERVICE_TERMS) && (
              <Route path='service-terms/*' element={<ServiceTermsPage />} />
            )}
            {isFeatureEnabled(FEATURES.PRIVACY_POLICY) && (
              <Route path='privacy-policy/*' element={<PrivacyPolicyPage />} />
            )}
            <Route path='help-center/*' element={<HelpCenterPageWrapper />} />
          </>
          <>
            <Route path='maintenance' element={<MaintenancePage />} />
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='retool/login' element={<RetoolLogin />} />
            <Route path='global/*' element={<GlobalWrapper />} />
            <Route path='logout' element={<Logout />} />
            {currentUser ? (
              <>
                {currentUser.onboard_step !== 'done' && (
                  <>
                    <Route path='your-info/*' element={<OnboardWrapper />} />
                    <Route path='mnda' element={<ClientSignature />} />
                    <Route path='*' element={<Navigate to='/your-info' />} />
                  </>
                )}
                {currentUser.onboard_step === 'done' &&
                  currentUser.company.mnda_status !== 'not_signed' && (
                    <Route path='/*' element={<PrivateRoutes />} />
                  )}
              </>
            ) : (
              <>
                <Route path='mnda/:id' element={<SalesSignature />} />
                <Route path='auth/*' element={<AuthPage />} />
                <Route element={<MasterLayoutForNoAuth />}>
                  {isFeatureEnabled(FEATURES.SUPPORT_PAGE) && (
                    <Route
                      path='support/*'
                      element={
                        <SuspenseView>
                          <SupportWrapper />
                        </SuspenseView>
                      }
                    />
                  )}
                  <Route
                    path='*'
                    element={
                      isFeatureEnabled(FEATURES.LANDING_PAGE) ? (
                        <Navigate to='/landing' />
                      ) : (
                        <Navigate to='/auth' />
                      )
                    }
                  />
                </Route>
              </>
            )}
          </>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspenseView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {AppRoutes, SuspenseView}
