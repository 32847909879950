import {useFormik} from 'formik'
import {lowerCase, startCase} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {PasswordMeterComponent} from '../../../../_gori/assets/ts/components'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik, SelectFormik} from '../../../../_gori/partials/widgets'
import {AcceptTermCheckbox} from '../../../../_gori/partials/widgets/form/AcceptTermCheckbox'
import {InputPassword} from '../../../../_gori/partials/widgets/form/InputPassword'
import AuthService from '../core/_requests'
import ReCAPTCHA from 'react-google-recaptcha'
import {isFeatureEnabled} from '../../../../_gori/helpers'
import {FEATURES} from '../../../../_gori/constants/_features'

const KEY_RECAPTCHA = process.env.REACT_APP_KEY_RECAPTCHA

export function Registration() {
  const intl = useIntl()
  const navigate = useNavigate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, authYup, infoYup, regexYup} = UseYupValidate()
  const [loading, setLoading] = useState(false)
  const [valid, setValid] = useState(false)
  const registrationSchema = Yup.object().shape({
    company_name: stringYup(50, 'COMPANY_NAME')
      .trim()
      .matches(/^(?!\s)(?=.*\S$)[a-zA-Z0-9\s]+$/, {
        message: intl.formatMessage(
          {id: 'INPUT_MUST_CONTAIN_LETTERS_DIGITS_SPACES'},
          {input: intl.formatMessage({id: 'COMPANY_NAME'})}
        ),
        excludeEmptyString: true,
      }),
    first_name: regexYup.inputText('FIRST_NAME', true, 50),
    last_name: regexYup.inputText('LAST_NAME', true, 50),
    phone: infoYup.phone('PHONE', true),
    monthly_shipment_volume: stringYup(255, 'MONTHLY_SHIPMENT_VOLUME', true),
    email: infoYup.email(),
    password: authYup.password(),
    password_confirmation: authYup.passwordConfirmation(),
    accept_terms: authYup.acceptTerms,
  })

  const initialValues = {
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    accept_terms: false,
  }

  const handleSubmitError = (errorsMessage: any) => {
    Object.entries(errorsMessage).forEach(([key, value]: [any, any]) => {
      for (let i = 0; i < value.length; i++) {
        const errMessage = value[i].split('|')

        formik.errors[key] = formik.errors[key]
          ? formik.errors[key] +
            ', ' +
            intl.formatMessage({id: errMessage[1]}, {input: startCase(lowerCase(errMessage[0]))})
          : intl.formatMessage({id: errMessage[1]}, {input: startCase(lowerCase(errMessage[0]))})
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setLoading(true)
        await AuthService.register(
          {...values, accept_terms: values.accept_terms},
          {cancelToken: newCancelToken()}
        )
        navigate('/auth/pending-activation')
      } catch (error: any) {
        if (isCancel(error)) return
        handleSubmitError(error?.response?.data?.error?.fields)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    const baseDomain = process.env.REACT_APP_DOMAIN
    if (document.location.host !== baseDomain) {
      const protocal = document.location.protocol
      document.location.replace(
        protocal + '//' + baseDomain + isFeatureEnabled(FEATURES.LANDING_PAGE) ? '/landing' : '/'
      )
    }
    PasswordMeterComponent.bootstrap()
  }, [])

  const getVersion = useCallback(async () => {
    try {
      await AuthService.getVersion()
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getVersion()
  }, [getVersion])
  const recaptchaRef = React.createRef()

  const monthlyShipmentOption = [
    {label: 'I_SHIP_OCCASIONALLY_UNDER_10_PACKAGES_PER_MONTH', value: 'under_10'},
    {label: '11_100_PACKAGES_PER_MONTH', value: '11_100'},
    {label: '101_500_PACKAGES_PER_MONTH', value: '101_500'},
    {label: '501_1000_PACKAGES_PER_MONTH', value: '501_1000'},
    {label: '1001_5000_PACKAGES_PER_MONTH', value: '1001_5000'},
    {label: 'MORE_THAN_5000_PACKAGES_PER_MONTH', value: 'more_5000'},
  ]
  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          {!valid ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={(valid: boolean | ((prevState: boolean) => boolean)) => {
                  setValid(valid)
                }}
                sitekey={KEY_RECAPTCHA}
                hl='en'
                isolated={true}
              />
            </div>
          ) : (
            <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
              <div className='mb-10 text-center'>
                <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'CREATE_AN_ACCOUNT'})}</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                  {intl.formatMessage({id: 'ALREADY_HAVE_AN_ACCOUNT'})}{' '}
                  <Link to='/auth/login' className='link-primary fw-bolder'>
                    {intl.formatMessage({id: 'SIGN_IN_HERE'})}
                  </Link>
                </div>
              </div>

              <div className='fv-row mb-7'>
                <InputTextFormik
                  labelClassName='form-label fw-bolder text-dark fs-6'
                  label={intl.formatMessage({id: 'COMPANY_NAME'})}
                  size='lg'
                  formik={formik}
                  name='company_name'
                  required
                />
              </div>
              <div className='row fv-row mb-9 fv-plugins-icon-container'>
                <div className='col-xl-6'>
                  <InputTextFormik
                    labelClassName='form-label fw-bolder text-dark fs-6'
                    label={intl.formatMessage({id: 'FIRST_NAME'})}
                    size='lg'
                    formik={formik}
                    name='first_name'
                    required
                  />
                </div>
                <div className='col-xl-6'>
                  <InputTextFormik
                    labelClassName='form-label fw-bolder text-dark fs-6'
                    label={intl.formatMessage({id: 'LAST_NAME'})}
                    size='lg'
                    formik={formik}
                    name='last_name'
                    required
                  />
                </div>
              </div>

              <div className='row fv-row mb-9 fv-plugins-icon-container'>
                <div className='col-xl-6'>
                  <InputTextFormik
                    labelClassName='form-label fw-bolder text-dark fs-6 required'
                    label={intl.formatMessage({id: 'PHONE_NUMBER'})}
                    size='lg'
                    formik={formik}
                    name='phone'
                    required={true}
                  />
                </div>
                <div className='col-xl-6'>
                  <SelectFormik
                    checkFormik
                    emptyDefault={false}
                    options={monthlyShipmentOption}
                    formik={formik}
                    name='monthly_shipment_volume'
                    required={true}
                    isSearchable={false}
                    hasUseIntl={true}
                    label={intl.formatMessage({id: 'SHIPMENT_VOLUME'})}
                    labelClassName='fw-bolder required'
                  />
                </div>
              </div>

              <div className='fv-row mb-7 fv-plugins-icon-container'>
                <InputTextFormik
                  labelClassName='form-label fw-bolder text-dark fs-6'
                  label={intl.formatMessage({id: 'EMAIL'})}
                  size='lg'
                  formik={formik}
                  name='email'
                  required
                />
              </div>
              <InputPassword
                className={'mb-10'}
                labelClassName='form-label fw-bolder text-dark fs-6'
                label={intl.formatMessage({id: 'PASSWORD'})}
                size={'lg'}
                strengthMessage={intl.formatMessage({id: 'PASSWORD_STRENGTH_MESSAGE'})}
                formik={formik}
                name={'password'}
                required
              />
              <InputPassword
                className={'mb-5'}
                labelClassName='form-label fw-bolder text-dark fs-6'
                label={intl.formatMessage({id: 'CONFIRM_PASSWORD'})}
                size='lg'
                openHighlight={false}
                formik={formik}
                name={'password_confirmation'}
                required
              />
              {isFeatureEnabled(FEATURES.SERVICE_TERMS) && (
                <AcceptTermCheckbox
                  className={'mb-10'}
                  labelCheck={intl.formatMessage({id: 'I_AGREE'})}
                  labelLink={intl.formatMessage({id: 'TERMS_AND_CONDITIONS'})}
                  formik={formik}
                  name={'accept_terms'}
                  showTermModal={() => {
                    if (blockPopUp('https://shipbae.com/service-terms')) {
                      toast.warning(
                        intl.formatMessage({
                          id: 'POP_UP_IS_BLOCKED',
                        })
                      )
                    }
                  }}
                />
              )}
              <div className='text-center'>
                <Button
                  className='btn btn-lg btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  loading={loading}
                  disabled={formik.isSubmitting || !formik.isValid}
                  event={formik.submitForm}
                />
              </div>
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  )
}
